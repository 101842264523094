import { useCallback, useEffect, useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fetchEditionAwarenessPillDismissed,
	setEditionAwarenessPillDismissed,
} from './services/index.tsx';

export const useJiraStandardToPremiumPillExperiment = () => {
	const [isDismissed, setIsDismissed] = useState(true);

	useEffect(() => {
		if (fg('edition_awareness_upsell_jira_aa_fg') || fg('edition_awareness_upsell_jira_ab_fg')) {
			fetchEditionAwarenessPillDismissed().then((result) => {
				setIsDismissed(!!result);
			});
		}
	}, []);

	const handleDismiss = useCallback(() => {
		if (fg('edition_awareness_upsell_jira_aa_fg') || fg('edition_awareness_upsell_jira_ab_fg')) {
			setIsDismissed(true);
			setEditionAwarenessPillDismissed(true);
		}
	}, []);

	return { isDismissed, handleDismiss };
};
