/**
 * @generated SignedSource<<dade92b467ff2b2647f1d4cc48e35173>>
 * @relayHash 1ec0c8490fd1aef2241765ab043d0f51
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2ff334655b52ef8e20130047553d8de52e873f24969701bae5a9d15c47dcca2c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4NotificationsDataRefetchableQuery$variables = Record<PropertyKey, never>;
export type Nav4NotificationsDataRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4NotificationsData">;
};
export type Nav4NotificationsDataRefetchableQuery = {
  response: Nav4NotificationsDataRefetchableQuery$data;
  variables: Nav4NotificationsDataRefetchableQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "Nav4NotificationsDataRefetchableQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4NotificationsData"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Nav4NotificationsDataRefetchableQuery",
    "selections": [
      {
        "concreteType": "InfluentsNotificationQuery",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "unseenNotificationCount"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2ff334655b52ef8e20130047553d8de52e873f24969701bae5a9d15c47dcca2c",
    "metadata": {},
    "name": "Nav4NotificationsDataRefetchableQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "7195e299075256b125f5f6ac2a647074";

export default node;
