import React from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import type { Nav4NotificationsData$key } from '@atlassian/jira-relay/src/__generated__/Nav4NotificationsData.graphql';
import type { Nav4NotificationsDataRefetchableQuery } from '@atlassian/jira-relay/src/__generated__/Nav4NotificationsDataRefetchableQuery.graphql';
import { Nav4Notifications } from './Nav4Notifications.tsx';

type Nav4NotificationsDataProps = {
	queryReference: Nav4NotificationsData$key;
};

export const Nav4NotificationsData = (props: Nav4NotificationsDataProps) => {
	const { queryReference } = props;
	const [fragmentData, refetch] = useRefetchableFragment<
		Nav4NotificationsDataRefetchableQuery,
		Nav4NotificationsData$key
	>(
		graphql`
			fragment Nav4NotificationsData on Query
			@refetchable(queryName: "Nav4NotificationsDataRefetchableQuery") {
				...Nav4Notifications
			}
		`,
		queryReference,
	);

	return <Nav4Notifications refetch={refetch} fragmentData={fragmentData} />;
};
