import {
	CONFIGURABLE_MENU_ID,
	type ConfigurableMenuId,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';

export const SECTION_ITEM_RECENT = 'recent';
export const SECTION_ITEM_STARRED = 'starred';
export const SECTION_ITEM_YOUR_APPS = 'yourApps';
export const SECTION_ITEM_YOUR_WORK = 'yourWork';
export const SECTION_ITEM_APPS = 'apps';
export const SECTION_ITEM_PLANS = 'plans';
export const SECTION_ITEM_PROJECTS = 'projects';
export const SECTION_ITEM_FILTERS = 'filters';
export const SECTION_ITEM_DASHBOARDS = 'dashboards';
export const SECTION_ITEM_OPERATIONS = 'operations';
export const SECTION_ITEM_TEAMS = 'teams';
export const SECTION_ITEM_ASSETS = 'assets';
export const SECTION_ITEM_MORE = 'more';
export const SECTION_ITEM_CUSTOMIZE_SIDEBAR = 'customiseSidebar';
export const SECTION_ITEM_OVERVIEW = 'overviews';
export const SECTION_ITEM_GOALS = 'goals';
export const SECTION_ITEM_ROADMAPS = 'roadmaps';
export const SECTION_ITEM_CUSTOMER_SERVICE = 'customerService';
export const SECTION_ITEM_CUSTOMER_SERVICE_ANALYTICS = 'customerServiceAnalytics';
export const GLOBAL_APP_SHORTCUT_ASSETS = 'assetsGlobalAppShortcut';
export const GLOBAL_APP_SHORTCUT_GOALS = 'goalsGlobalAppShortcut';

export type SectionItemType =
	| typeof SECTION_ITEM_RECENT
	| typeof SECTION_ITEM_STARRED
	| typeof SECTION_ITEM_YOUR_APPS
	| typeof SECTION_ITEM_YOUR_WORK
	| typeof SECTION_ITEM_APPS
	| typeof SECTION_ITEM_PLANS
	| typeof SECTION_ITEM_PROJECTS
	| typeof SECTION_ITEM_FILTERS
	| typeof SECTION_ITEM_DASHBOARDS
	| typeof SECTION_ITEM_OPERATIONS
	| typeof SECTION_ITEM_TEAMS
	| typeof SECTION_ITEM_ASSETS
	| typeof SECTION_ITEM_MORE
	| typeof SECTION_ITEM_CUSTOMIZE_SIDEBAR
	| typeof SECTION_ITEM_OVERVIEW
	| typeof SECTION_ITEM_GOALS
	| typeof SECTION_ITEM_ROADMAPS
	| typeof SECTION_ITEM_CUSTOMER_SERVICE
	| typeof SECTION_ITEM_CUSTOMER_SERVICE_ANALYTICS
	| typeof GLOBAL_APP_SHORTCUT_ASSETS
	| typeof GLOBAL_APP_SHORTCUT_GOALS;

export const SECTION_ITEM_MAP: Record<ConfigurableMenuId, SectionItemType> = {
	[CONFIGURABLE_MENU_ID.APPS]: SECTION_ITEM_APPS,
	[CONFIGURABLE_MENU_ID.ASSETS]: SECTION_ITEM_ASSETS,
	[CONFIGURABLE_MENU_ID.DASHBOARDS]: SECTION_ITEM_DASHBOARDS,
	[CONFIGURABLE_MENU_ID.FILTERS]: SECTION_ITEM_FILTERS,
	[CONFIGURABLE_MENU_ID.OPERATIONS]: SECTION_ITEM_OPERATIONS,
	[CONFIGURABLE_MENU_ID.PLANS]: SECTION_ITEM_PLANS,
	[CONFIGURABLE_MENU_ID.PROJECTS]: SECTION_ITEM_PROJECTS,
	[CONFIGURABLE_MENU_ID.RECENT]: SECTION_ITEM_RECENT,
	[CONFIGURABLE_MENU_ID.STARRED]: SECTION_ITEM_STARRED,
	[CONFIGURABLE_MENU_ID.TEAMS]: SECTION_ITEM_TEAMS,
	[CONFIGURABLE_MENU_ID.GOALS]: SECTION_ITEM_GOALS,
	[CONFIGURABLE_MENU_ID.OVERVIEWS]: SECTION_ITEM_OVERVIEW,
	[CONFIGURABLE_MENU_ID.ROADMAPS]: SECTION_ITEM_ROADMAPS,
	[CONFIGURABLE_MENU_ID.CUSTOMER_SERVICE]: SECTION_ITEM_CUSTOMER_SERVICE,
	[CONFIGURABLE_MENU_ID.CUSTOMER_SERVICE_ANALYTICS]: SECTION_ITEM_CUSTOMER_SERVICE_ANALYTICS,
	[CONFIGURABLE_MENU_ID.GLOBAL_APP_GOALS]: GLOBAL_APP_SHORTCUT_GOALS,
	[CONFIGURABLE_MENU_ID.GLOBAL_APP_ASSETS]: GLOBAL_APP_SHORTCUT_ASSETS,
};
