/**
 * @generated SignedSource<<279acb7c9233a14a3eaeb49e7a5a0b82>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4NotificationsData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Notifications">;
  readonly " $fragmentType": "Nav4NotificationsData";
};
export type Nav4NotificationsData$key = {
  readonly " $data"?: Nav4NotificationsData$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4NotificationsData">;
};

import Nav4NotificationsDataRefetchableQuery_graphql from './Nav4NotificationsDataRefetchableQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": Nav4NotificationsDataRefetchableQuery_graphql
    }
  },
  "name": "Nav4NotificationsData",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4Notifications"
    }
  ],
  "type": "Query"
};

(node as any).hash = "7195e299075256b125f5f6ac2a647074";

export default node;
